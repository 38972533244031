@import './assets/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-gradient {
    background: linear-gradient(to right, #DE61E8, #6177E8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body {
    font-family: 'BRCobane', sans-serif;
}